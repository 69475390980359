<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="editedItem.BrukerID"
        item-text="Description"
        item-value="Value"
        :items="BrukerItems"
        label="Bruker"
      ></v-autocomplete>
      <wtDatetime
        v-model="editedItem.MoteDato"
        label="Mote dato"
        onlyDate
      ></wtDatetime>
      <v-text-field
        v-model="editedItem.Overskrift"
        label="Overskrift"
        maxlength="50"
      ></v-text-field>
      <wtMultiSelect
        v-model="editedItem.itemsMoteDeltagere"
        item-text="Description"
        item-value="Value"
        :items="BrukerItems"
        label="Mote deltagere"
      ></wtMultiSelect>
    </v-col>
  </v-row>
</template>
<script>
import KeyValue from "../global/KeyValue";
import wtDatetime from "../components/wtDatetime.vue";
import wtMultiSelect from "../components/wtMultiSelect.vue";

export default {
  extends: KeyValue,
  name: "dlgMote",
  components: {
    wtDatetime,
    wtMultiSelect,
  },

  model: {
    prop: "editedItemIn",
    event: "input",
  },

  props: {
    keyProsjektID: Number,
    editedItemIn: Object,
    editMode: Number, //v-if="editMode !== -1" - Not in "new"
  },

  computed: {
    editedItem() {
      return this.editedItemIn
    }
  },

  created() {
    this.GetBrukerCode();
  },

  methods: {
    output: function () {
      // pass back to the parent
      this.$emit("input", this.editedItem);
    },
  },
};
</script>
